/* eslint-disable import/first */
import React from "react";
import deann from "./deann.png"
import lisa from "./lisa.png"

function FunStuff() {
  return (
    <div className="fun" style={{display:"flex",  padding:"4em 0em 10% 3%", fontSize:"1.4em"}} >
        <div className="funText">
        <ul style={{marginBottom:"3%"}} >
            <li><div>Send us a fun pic from your wedding and see it featured at our reception!  Send to: SandSPridmore@gmail.com</div>
            </li>
            <li>
            Registered at <a
    href="https://www.crateandbarrel.com/gift-registry/sami-and-shawn-pridmore/r7082645"
    style={{color:"brown"}}
    >
        Crate & Barrel</a>
            </li>
            <li>Or donate to our honeymoon fund on Venmo!  
                <div>
                <a  style={{color:"brown"}} href="https://venmo.com/pridmorehoneymoonfund" target="blank" >@PridmoreHoneymoonFund</a>

                </div>
                </li>

        </ul>
        <div style={{marginLeft:"3%", marginBottom:"2%", marginRight:"12%"}} >
            <img style={{width:"80%", minWidth: "16em", border:"5px solid black"}} src={deann} alt="" />
        </div>
        </div>
        <div  className="funPics">
        <div> 
                <img style={{width:"65%", minWidth: "12em", border:"5px solid black", margin:"0 0 0 0"}} src={lisa} alt="" />
            </div>
           
            <div style={{height:"3em"}}></div>
            <div></div>

        </div>
    
    </div>
   
   );
}

export default FunStuff;
