/* eslint-disable import/first */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  RSVP,
  Home,
  FunStuff
} from "./components";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router >
    <Navigation />
    <Routes>
    <Route path="/" element={<Home />} />
      <Route path="/RSVP" element={<RSVP />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/FunStuff" element={<FunStuff />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
  
);

serviceWorker.unregister();
