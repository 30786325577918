/* eslint-disable import/first */
import React from "react";
import { NavLink } from "react-router-dom";
import "../index.css";

function Navigation() {
  return (
    <div 
    className="navigation"
    style={{
      position:"fixed",
       width:"100%",
        zIndex:"1"}}
    >
      <nav  className="navbar navbar-expand navbar-dark bg-dark">
        <div   style={{ display: "flex", width:"100%" }}>
          {/* <NavLink className="dancingFont" to="/">
            Sami and Shawn
          </NavLink> */}
          <div >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  style={{ color: "#949D9A" }}
                  className="nav-link"
                  to="/"
                >
                  Home
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/RSVP">
                  Rsvp
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/FunStuff">
                  Fun
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
