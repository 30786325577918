/* eslint-disable import/first */
import React from "react";
import image from './samiTurtles.jpg'
import timage from './tortoiseOutline.png'

function Home() {
  return (
    <div 
    // className="home"
    >
      <div 
      // class="container"
      >
        <div class="myrow align-items-center "  style={{marginRight:"0px !important"}} >

          <div class="col-lg-6" style={{marginTop:"5em", paddingRight:"0px"}}>
            <img 
              class="img-fluid rounded mb-4 mb-lg-0"
              src={image}
              id="your-img"
            />
          </div>
          <div class="col-lg-5 bringItDown" style={{marginTop:"2em"}}>
            <div style={{textAlign:"center",}}>
            <h1 class="font-weight-light" style={{fontSize:"3em", fontFamily:"Sacramento"}}  className="dancingFontDark">Sami and Shawn</h1>
            <h1 class="font-weight-light" style={{fontSize:"3em", fontFamily:"Sacramento"}} className="dancingFontDark">are getting</h1>
            <h1 class="font-weight-light" style={{fontSize:"3em", fontFamily:"Sacramento"}}  className="dancingFontDark">married</h1>
            </div>
            <div style={{marginRight:"2.5em"}}>
              <img src={timage} alt="" width="6%" style={{ display:"block", marginLeft: "auto", marginRight: "auto", paddingTop:"1em"}}/>
            </div>
            <div style={{textAlign:"center",marginRight:"2.5em", paddingTop:"2em"}}>
            <p class="font-weight-light" >
              At Murdock Manor
              </p>
              <p  className="font-weight-light homeText" >
              Saturday, The Twenty Second Day of June 
              </p>
              <p  class="font-weight-light" >
               Two Thousand Twenty Four
              </p>
              <div style={{height:"3em"}} class="font-weight-light" >  
              </div>
             
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Home;
