/* eslint-disable import/first */
import React from "react";

function Footer() {
  return (
    <div  
    className="footer"
    >
      <footer style={{height:"3em"}} 
      className="bg-dark fixed-bottom"
      >
        <div
        // className="container"
        >
          <p style={{fontSize:"1.5em", fontFamily:"Sacramento", marginTop:".3em"}}  className=" text-center text-white">Bring your dancing shoes!</p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
