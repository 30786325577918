import React, { useEffect, useState } from "react";
import image from './turtle.png'
import { FormGroup, InputGroup, RadioGroup, Radio, Button, NumericInput, Portal, Dialog  } from '@blueprintjs/core'
import '../index.css'
import axios from 'axios'

const RSVP = () => {
  const [radio, setRadio] = useState("")
  const [name, setName] = useState("")
  const [attendance, setAttendance] = useState(0)
  const [ready, setReady] = useState(false);
  const [submitted, setSubmitted] = useState(false);


  axios.defaults.headers.post['Content-Type'] = 'text/plain';
  const dothing= (e) => {
    setSubmitted(true)
    // e.preventDefault();
 var form = document.forms.myForm;

  const data = new FormData(form);
  // data.append('Name', 'Shawn')
  fetch("https://script.google.com/macros/s/AKfycbzjWtfyBLcSX-aKMJZOWtuNrv9l9p8Zxx5hKgQi8I3M8cSEiEPoqcVwXXPphuiGo0YhBw/exec", {
    mode: 'no-cors',
    method: "POST",
    body: data,
  })
  }

  useEffect(() => {
    if(name.trim() != ""){
    if(radio.trim() == "0" || radio.trim() == "1"){
      setReady(true)
      return;
    }
    else if(radio.trim() == "2" && attendance > 0){
      setReady(true)
      return;
    }
  }
  setReady(false)

//  return true;
  }, [name, radio, attendance])

  return (
    <div style={{ width: "100%" }}>
      <div>
        <div className="rsvpContainer" style={{ marginTop: "", display: "flex" }}
        >
          <div style={{ margin: "3em 0 0 1em"}} className="turtleFlex">
            <div style={{ flex: "1 ",}}
            >
              <img
                src={image}
                id="tut-img"
              />
            </div>
            <div className="bringItDownTurtle" style={{ marginBottom:"5em", marginLeft:"4em", flex: "1 1 auto"  }}>
            {!submitted && (
              <h1 className="font-weight-light, dancingFontDark" style={{fontFamily:'Dancing Script', paddingRight:"0", paddingBottom:"",
              //  borderBottom:"1px solid black"
               }}>Rsvp</h1>
            )}
                 {submitted && (
              <h1 className="font-weight-light, dancingFontDark" style={{fontFamily:'Dancing Script', paddingRight:"0", paddingBottom:"",
              //  borderBottom:"1px solid black"
               }}>You've registered! Thank you</h1>
            )}
            {!submitted && (

              <form className="form" id="myForm"
              //  action="https://script.google.com/macros/s/AKfycbzjWtfyBLcSX-aKMJZOWtuNrv9l9p8Zxx5hKgQi8I3M8cSEiEPoqcVwXXPphuiGo0YhBw/exec" method="post"
             onSubmit={(e) => dothing(e)}
            target="theiframe"
               >
              <FormGroup
                  label="Name">
                  <InputGroup onChange={(e) => {setName(e.target.value)}} name="Name" id="Name" placeholder="" />
                </FormGroup>
                <RadioGroup   className="radioGroup" name="Message"  onChange={(e) => { setRadio(e.target.value) }} selectedValue={radio}>
                  <Radio style={{ "paddingLeft": ".3em" }} name="Message" label=" Regretfully cannot attend" value="0" />
                  <Radio style={{ "paddingLeft": ".3em" }} name="Message" label=" Enthusiastically cannot attend" value="1" />
                  <Radio style={{ "paddingLeft": ".3em" }} name="Message" label=" Can attend" value="2" />

                </RadioGroup>
                {radio == "2" && (
                  <div>

                <FormGroup  style={{ "paddingLeft": "1em", "marginBottom":"-2em !important", marginTop:".5em" }}
                  label="Number Attending">
                  <NumericInput  clampValueOnBlur={true} style={{ "paddingLeft": "1em", "marginBottom":"-2em !important" }} onChange={(e) => {setAttendance(e.target.value)}} small min={0} max={8}  buttonPosition="none" name="Attendance" id="Attendance" type="number"/>
                </FormGroup>

                </div>
                )}
                {!submitted && 
                (
                  <div 
                  >
                <Button 
                 intent="primary" style={ !ready ? { "display": "none"}
                 : { "color": "white", "borderRadius": "3px", "background": "#343A40", "fontSize": "large", marginTop:".7em" }} 
                 type="submit" 
                 value="Submit"
                  text="Rsvp!" >
                  </Button>
                 </div>

                )}
            
              </form>
            )}

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default RSVP;
